<template>
  <v-list-item @click="sheet = true">
    <v-list-item-action>
      <v-btn icon :disabled="!item.file">
        <v-icon>mdi-file-document</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-content>
      {{ item.description }}
      <v-list-item-subtitle v-if="item.optionalSlot">{{
        item.optionalSlot.description
      }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="item.canChoose && !disabled">
      <v-switch
        :input-value="item.hasChosenNext"
        inset
        readonly
        :loading="loading"
        @click.stop="toggle()"
      ></v-switch>
    </v-list-item-action>
    <v-bottom-sheet v-model="sheet" inset max-width="500">
      <v-card tile
        ><v-system-bar color="success" window
          >Fach<v-spacer></v-spacer
          ><v-btn icon @click="sheet = false"
            ><v-icon small>mdi-close</v-icon></v-btn
          ></v-system-bar
        >
        <v-card-text class="title pt-8 text-center">
          {{ item.description }}
        </v-card-text>
        <template v-if="item.canChoose">
          <v-card-text class="display text-center" v-if="item.hasChosenNext">
            <v-avatar color="success">
              <v-icon large color="white">mdi-check-bold</v-icon> </v-avatar
            ><br />
            gewählt
          </v-card-text>
          <v-card-text class="display text-center" v-else>
            <v-avatar color="error"
              ><v-icon large color="white">mdi-close-thick</v-icon> </v-avatar
            ><br />
            <template v-if="disabled">kann nicht gewählt werden</template>
            <template v-else>nicht gewählt</template>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text
            class="display text-center"
            v-if="item.hasChosenNow && !item.hasChosenNext"
          >
            <v-avatar color="info"
              ><v-icon color="white">mdi-check-bold</v-icon></v-avatar
            ><br />
            momentan besucht
          </v-card-text>
          <v-card-text
            class="display text-center"
            v-if="item.hasChosenNow && item.hasChosenNext"
          >
            <v-avatar color="info"
              ><v-icon color="white">mdi-check-bold</v-icon></v-avatar
            ><br />
            automatische Anmeldung
          </v-card-text>
        </template>
        <v-divider />
        <v-list>
          <v-list-item :disabled="!item.file" @click="document()">
            <v-list-item-icon
              ><v-icon :disabled="!item.file"
                >mdi-file-document</v-icon
              ></v-list-item-icon
            >
            <v-list-item-content>
              Ausschreibungstext
              {{ !item.file ? " nicht verfügbar" : " anzeigen (PDF)" }}
            </v-list-item-content></v-list-item
          >
          <v-list-item
            @click="toggle()"
            v-if="item.canChoose && !disabled"
            :three-line="item.optionalSlot != null"
          >
            <v-list-item-icon>
              <v-icon v-if="item.hasChosenNext">mdi-close</v-icon>
              <v-icon v-else>mdi-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-if="item.hasChosenNext">
              <v-list-item-title> abwählen </v-list-item-title>
              <v-list-item-subtitle v-if="item.optionalSlot">
                es kann nur ein Angebot in der Schiene «{{
                  item.optionalSlot.description
                }}» gewählt werden
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title> wählen </v-list-item-title>
              <v-list-item-subtitle v-if="item.optionalSlot">
                es kann nur ein Angebot in der Schiene «{{
                  item.optionalSlot.description
                }}» gewählt werden
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                :input-value="item.hasChosenNext"
                inset
                readonly
                :loading="loading"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="disabled" three-line>
            <v-list-item-icon>
              <v-icon>mdi-close</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> nicht wählbar </v-list-item-title>
              <v-list-item-subtitle
                >ein anderes Angebot in der Schiene «{{
                  item.optionalSlot.description
                }}» ist gewählt
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider />
        <v-card-actions
          ><v-btn text block @click="sheet = false">schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-list-item>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChoiceItem",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    item: {},
    student: {},
  },
  data() {
    return {
      loading: false,
      sheet: false,
    };
  },
  methods: {
    async toggle() {
      this.loading = true;
      const data = {
        optionalSubject: this.item.id,
        choose: !this.item.hasChosenNext,
        student: this.student,
      };
      await this.apiPost({
        resource: "optional/choice",
        data: data,
      });
      this.$emit("updated", this.item);
    },
    async document() {
      const headers = new Headers();
      const accessToken = await this.$auth.getToken();

      headers.append("Authorization", `Bearer ${accessToken}`);
      const options = {
        //cache: 'no-cache',
        headers: headers,
        method: "GET",
      };
      let url = `${this.apiBase()}optional/file/${this.item.file}`;
      const response = await fetch(url, options);
      let link = document.createElement("a");
      link.download = this.item.file + ".pdf";
      link.href = window.URL.createObjectURL(await response.blob());
      link.click();
    },
  },
  watch: {
    item() {
      this.loading = false;
    },
  },
});
</script>
