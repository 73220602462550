<template>
  <div>
    <v-row v-if="data">
      <v-col cols="12" md="4">
        <v-card class="mb-4" v-if="!forMe" flat>
          <v-card-text class="title pt-8 text-center">
            <PersonName :value="data.student" />
          </v-card-text>
          <v-card-text class="display text-center">
            <v-avatar>
              <PortraitImage :value="data.student" />
            </v-avatar>
            <br />
            {{ data.student.ext }}</v-card-text
          >
        </v-card>
        <template v-if="data.enabled">
          <v-card>
            <v-system-bar>Infos</v-system-bar>
            <v-alert type="info" text :prominent="!$vuetify.breakpoint.xs">
              Schüler*innen müssen ihre Wahl bis am
              <DateValue :value="settings.studentEndDate" long /> um
              {{ settings.studentEndTime }} Uhr abschliessen.
              <template v-if="!forMe">
                <br />Klassenlehrkräfte bis am
                <DateValue :value="settings.teacherEndDate" long /> um
                {{ settings.teacherEndTime }} Uhr.
              </template>
            </v-alert>
          </v-card>
          <v-card v-if="data.hasConfirmed">
            <v-system-bar>Bestätigung</v-system-bar>
            <v-alert
              type="success"
              text
              :prominent="!$vuetify.breakpoint.xs"
              class="mb-0"
            >
              Du hast deine Wahl bestätigt
            </v-alert>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="error" block depressed @click="confirm(false)"
                >zurücknehmen</v-btn
              >
            </v-card-actions>
          </v-card>
          <v-card v-else>
            <v-system-bar>Bestätigung</v-system-bar>
            <v-alert
              type="error"
              text
              :prominent="!$vuetify.breakpoint.xs"
              class="mb-0"
            >
              Du hast deine Wahl noch nicht bestätigt. Bitte bestätige deine
              Wahl, auch wenn du nichts wählst!
            </v-alert>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="success" depressed block @click="confirm(true)"
                >bestätigen</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
        <template v-else>
          <v-alert type="info" text :prominent="!$vuetify.breakpoint.xs">
            Die Anmeldung ist aktuell geschlossen.
          </v-alert>
        </template>
        <SciencePlus :id="id" />
      </v-col>
      <v-col>
        <v-card flat max-width="500">
          <v-card class="mb-4">
            <v-system-bar>dieses Semester besucht</v-system-bar>
            <v-list>
              <ChoiceItem
                v-for="item in now"
                :key="'now' + item.id"
                :item="item"
                :student="id"
                @updated="fetchData"
              >
              </ChoiceItem>
            </v-list>
          </v-card>
          <template v-if="data.enabled">
            <v-card class="mb-4">
              <v-system-bar>nächstes Semester gewählt</v-system-bar>
              <v-list>
                <ChoiceItem
                  v-for="item in chosen"
                  :key="'chosen' + item.id"
                  :item="item"
                  :student="id"
                  @updated="fetchData"
                >
                </ChoiceItem>
              </v-list>
            </v-card>
          </template>
          <v-card class="mb-4" v-if="available.length > 0">
            <v-system-bar>zur Wahl stehen</v-system-bar>
            <v-list>
              <ChoiceItem
                v-for="item in available"
                :key="'available' + item.id"
                :item="item"
                :student="id"
                @updated="fetchData"
                :disabled="disabled(item)"
              >
              </ChoiceItem>
            </v-list>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ChoiceItem from "./ChoiceItem.vue";
import DateValue from "common/components/DateValue.vue";
import PersonName from "common/components/PersonName.vue";
import PortraitImage from "common/components/PortraitImage.vue";
import SciencePlus from "./SciencePlus.vue";
export default defineComponent({
  components: {
    ChoiceItem,
    DateValue,
    PersonName,
    PortraitImage,
    SciencePlus,
  },
  props: { id: { type: Number, default: 0 } },
  data() {
    return {
      loading: false,
      saving: false,
      data: null,
      search: "",
      endDate: null,
      endTime: null,
    };
  },
  computed: {
    forMe() {
      return this.id == 0;
    },
    chosenSlotIds() {
      return [
        ...new Set(
          this.data.optionalSubjects
            .filter((el) => el.hasChosenNext && el.optionalSlot)
            .map((el) => el.optionalSlot.id)
        ),
      ];
    },
    available() {
      return this.data.optionalSubjects.filter(
        (el) => el.canChoose && !el.hasChosenNext
      );
    },
    chosen() {
      return this.data.optionalSubjects.filter((el) => el.hasChosenNext);
    },

    now() {
      return this.data.optionalSubjects.filter((el) => el.hasChosenNow);
    },
  },
  methods: {
    disabled(item) {
      return (
        item.optionalSlot &&
        this.chosenSlotIds.find((x) => x == item.optionalSlot.id) != null
      );
    },
    async confirm(value) {
      this.saving = true;
      await this.apiPost({
        resource: "optional/choice",
        data: { confirm: value, student: this.id },
      });
      this.saving = false;
      this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      if (this.id) {
        this.data = await this.apiList({
          resource: "optional/choice",
          query: "student=" + this.id,
        });
      } else {
        this.data = await this.apiList({ resource: "optional/choice" });
      }
      this.loading = false;
    },
    async fetchAll() {
      this.loading = true;
      this.settings = await this.apiList({ resource: "optional/settings" });
      await this.fetchData();
      this.loading = false;
    },
    async fetchItemData(item) {
      const update = await this.apiList({
        resource: "optional/choice",
        query: "student=" + this.id,
      });
      const index = update.optionalSubjects.findIndex(
        (el) => el.id === item.id
      );
      this.data.optionalSubjects.splice(
        index,
        1,
        update.optionalSubjects[index]
      );
    },
    async toggle(item) {
      this.$set(item, "loading", true);
      const data = {
        optionalSubject: item.id,
        choose: !item.hasChosenNext,
        student: this.id,
      };
      await this.apiPost({ resource: "optional/choice", data: data });
      this.fetchItemData(item);
    },
  },

  async created() {
    this.fetchAll();
  },
});
</script>
